<template>
  <Content class="overview">
    <h1>Survey Responses</h1>

    <div v-for="surveyResponse in surveyResponses" :key="surveyResponse.id">
      <div @click="exportToCSV(surveyResponse)">
        <LevelCard
          category="Response"
          :title="surveyResponse.id"
          :image-src="
            'https://firebasestorage.googleapis.com/v0/b/sustainabl-d1b01.appspot.com/o/level%2FHybrid-Intro-header.svg?alt=media&token=ff426eab-2346-4660-a068-00996f474a50'
          "
        />
      </div>
    </div>
    <FlexContainer>
      <FlexItem padding="normal" :grow="true">
        <TabNavigation :items="tabs" :active="currentTab" />
        <div v-show="currentTab === 0" class="tab">
          <ButtonRouterLink type="success" :to="`/journey/add`">
            Add Journey
          </ButtonRouterLink>
          <table>
            <tr>
              <th>Live</th>
              <th />
              <th>Journey</th>
              <th>Price</th>
              <th>Publisher</th>
            </tr>
            <tr v-for="(journey, index) in orderedJourneys" :key="index">
              <td>
                <i
                  v-if="journey.live"
                  class="fas fa-badge-check u__color--success"
                />
                <i v-else class="fal fa-badge u__color--success" />
              </td>
              <td>
                <ImageItem
                  :src="journey.imagePath"
                  width="24px"
                  height="24px"
                />
              </td>
              <td>{{ journey.name }}</td>
              <td />
              <td />
              <td width="10%">
                <FlexContainer>
                  <FlexItem>
                    <ButtonIcon
                      type="secondary"
                      icon="edit"
                      size="small"
                      @onClick="editJourney(journey.id)"
                    />
                  </FlexItem>
                  <FlexItem>
                    <ButtonIcon
                      type="tertiary"
                      icon="bolt"
                      size="small"
                      @onClick="toggleDealLive(journey.id)"
                    />
                  </FlexItem>
                  <FlexItem>
                    <ButtonIcon
                      type="primary"
                      icon="trash"
                      size="small"
                      @onClick="deleteDeal(journey.id)"
                    />
                  </FlexItem>
                </FlexContainer>
              </td>
            </tr>
          </table>
        </div>
        <div v-show="currentTab === 1" class="tab">
          <table>
            <tr>
              <th>Live</th>
              <th />
              <th>Deals</th>
              <th>Brand</th>
              <th>Name</th>
              <th>Colour</th>
            </tr>
            <tr v-for="(level, index) in orderedLevels" :key="index">
              <td>
                <i
                  v-if="level.live"
                  class="fas fa-badge-check u__color--success"
                />
                <i v-else class="fal fa-badge u__color--success" />
              </td>
              <td>
                <ImageItem
                  :src="level.cardImagePath"
                  width="24px"
                  height="24px"
                />
              </td>
              <td />
              <td>
                <b>{{ level.name }}</b>
              </td>
              <td />
              <td />
              <td width="15%">
                <FlexContainer>
                  <FlexItem>
                    <ButtonIcon
                      type="success"
                      icon="badge"
                      size="small"
                      @onClick="createDealFromProduct(level.id)"
                    />
                  </FlexItem>
                  <FlexItem>
                    <ButtonIcon
                      type="primary"
                      icon="copy"
                      size="small"
                      @onClick="duplicateProduct(level.id)"
                    />
                  </FlexItem>
                  <FlexItem>
                    <ButtonIcon
                      type="secondary"
                      icon="edit"
                      size="small"
                      @onClick="editProduct(level.id)"
                    />
                  </FlexItem>
                  <FlexItem>
                    <ButtonIcon
                      type="tertiary"
                      icon="bolt"
                      size="small"
                      @onClick="toggleProductLive(level.id)"
                    />
                  </FlexItem>
                  <FlexItem>
                    <ButtonIcon
                      type="primary"
                      icon="trash"
                      size="small"
                      @onClick="
                        confirmDeleteProduct(
                          product.id,
                          `${level.brand} ${level.name} ${level.colour}`
                        )
                      "
                    />
                  </FlexItem>
                </FlexContainer>
              </td>
            </tr>
          </table>
        </div>
      </FlexItem>
    </FlexContainer>
    <Modal :show="showModal.journey" @closeModal="showModal.journey = false">
      <div slot="header">
        <h2>{{ formMode.journey }} Journey</h2>
      </div>
      <div slot="body">
        <JourneyForm
          :mode="formMode.journey"
          :journey-id="currentJourney"
          @onSubmit="onSubmit('journey')"
        />
      </div>
    </Modal>
    <Modal :show="showModal.level" @closeModal="showModal.level = false">
      <div slot="header">
        <h2>{{ formMode.level }} Level</h2>
      </div>
      <div slot="body">
        <LevelForm
          :mode="formMode.level"
          :journey-id="currentJourney"
          :level-id="currentLevel"
          @onSubmit="onSubmit('level')"
        />
      </div>
    </Modal>
    <Modal :show="showModal.delete" @closeModal="showModal.delete = false">
      <div slot="header">
        <h2>Are you sure you want to delete {{ currentDeletionItem.name }}?</h2>
      </div>
      <div slot="footer">
        <Button type="secondary" @onClick="showModal.delete = false">
          Cancel
        </Button>
        <Button
          type="primary"
          @onClick="confirmDeleteLevel(currentDeletionItem.id)"
        >
          Confirm
        </Button>
      </div>
    </Modal>
  </Content>
</template>

<script>
import exportFromJSON from 'export-from-json'
import { orderBy } from 'lodash'
import { mapState, mapActions } from 'vuex'
import JourneyForm from '@/components/admin/JourneyForm'
import LevelForm from '@/components/admin/LevelForm'
import LevelCard from '@/components/global/ui/cards/LevelCard.vue'
const exportType = exportFromJSON.types.csv
export default {
  components: {
    JourneyForm,
    LevelForm,
    LevelCard
  },
  data: function() {
    return {
      currentTab: 0,
      tabs: [
        { href: '#journeys', text: 'Journeys', onClick: this.onChangeTab }
      ],
      showModal: {
        journey: false,
        level: false,
        delete: false
      },
      formMode: {
        journey: 'add',
        level: 'add'
      },
      currentJourney: null,
      currentLevel: null,
      currentDeletionItem: {}
    }
  },
  computed: {
    ...mapState({
      journeys: state => state.journeys.journeys,
      levels: state => state.levels.levels,
      surveyResponses: state => state.surveyResponses.surveyResponses
    }),
    orderedJourneys() {
      return orderBy(this.journeys, ['name', 'subtitle'], ['asc', 'asc'])
    },
    orderedLevels() {
      return orderBy(this.levels, ['name'], ['asc'])
    }
  },
  mounted() {
    console.log('mounted', this.$route)

    this.$store.dispatch('surveyResponses/fetchAndAdd')
    if (this.$route.hash) {
      this.currentTab = this.tabs.map(tab => tab.href).indexOf(this.$route.hash)
    }
  },
  methods: {
    ...mapActions({
      toggleProductLive: 'product/toggleLive',
      toggleDealLive: 'deal/toggleLive',
      deleteProduct: 'product/deleteProduct'
    }),
    exportToCSV(_data) {
      exportFromJSON({
        data: Object.entries(_data.response),
        fileName: 'Survey-Response',
        exportType
      })
    },
    onChangeTab(index) {
      this.currentTab = index
    },
    onSubmit(type) {
      setTimeout(() => {
        console.log('onSubmit', type)
        this.showModal[type] = false
      }, 2000)
    },
    createDealFromProduct(id) {
      this.formMode.deal = 'add'
      this.currentProduct = id
      this.showModal.deal = true
    },
    duplicateProduct(id) {
      this.formMode.product = 'duplicate'
      this.currentProduct = id
      this.showModal.product = true
    },
    editJourney(id) {
      this.formMode.journey = 'edit'
      this.currentJourney = id
      this.showModal.journey = true
    },
    editLevel(id) {
      this.formMode.level = 'edit'
      this.currentLevel = id
      this.showModal.level = true
    },
    confirmDeleteLevel(id, name) {
      this.showModal.delete = true
      this.currentDeletionItem = {
        type: 'level',
        id,
        name
      }
    },
    deleteDeal() {},
    deletePublisher() {}
  }
}
</script>

<style lang="scss" scoped>
.tab {
  background: $color-white;
  padding: $padding;
}

table {
  width: 100%;

  tr:nth-of-type(2n) {
    background: $color-gray--lightest;
  }

  th,
  td {
    padding: 0.8rem;
    vertical-align: middle;
  }

  th {
    font-weight: 400;
  }
}
</style>
